<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg">
        <el-col :span="6">
          <el-input
            placeholder="请输入公司名称/联系方式/法人姓名"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :height="height"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="公司名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.companyName }}</template>
        </el-table-column>
        <el-table-column label="公司分类" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.type }}</template>
        </el-table-column>
        <el-table-column
          label="联系方式"
          align="center"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="法人姓名" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.legalPersonName
          }}</template>
        </el-table-column>
        <el-table-column
          label="法人身份证号"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.legalPersonIdCardNo
          }}</template>
        </el-table-column>
        <el-table-column label="公司logo" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image
              style="width: 60px"
              :src="scope.row.companyLogo"
              :preview-src-list="[scope.row.companyLogo]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="营业执照照片"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.businessLicenseUrl"
              :preview-src-list="[scope.row.businessLicenseUrl]"
            ></el-image>
            <!-- {{ scope.row.businessLicenseUrl }} -->
          </template>
        </el-table-column>
        <el-table-column label="公司简介" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.companyInfo }}</template>
        </el-table-column>
        <el-table-column label="状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: #fbc015" v-if="scope.row.status == 0"
              >审核中</span
            >
            <span style="color: green" v-if="scope.row.status == 1"
              >审核通过</span
            >
            <span style="color: red" v-if="scope.row.status == 2"
              >审核拒绝</span
            >
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDt }}</template>
        </el-table-column>

        <el-table-column
          label="提交用户名称"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="edit(scope.row)" size="small"
              >编辑</el-button
            > -->
            <span class="edit" @click="edit(scope.row)">编辑</span>
            <span class="review" @click="review(scope.row)">审核</span>
            <!-- <el-button type="text" @click="review(scope.row)"
              >审核</el-button
            > -->
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="公司名称：" prop="siteName">
              <el-input
                v-model="form.companyName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司分类：" prop="siteName">
              <el-input v-model="form.type" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式：" prop="siteName">
              <el-input
                v-model="form.phone"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人姓名：" prop="siteName">
              <el-input
                v-model="form.legalPersonName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人身份证号：" prop="siteName">
              <el-input
                v-model="form.legalPersonIdCardNo"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司logo：" prop="siteName">
              <el-input
                v-model="form.companyLogo"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业执照照片：" prop="siteName">
              <el-input
                v-model="form.businessLicenseUrl"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司简介：" prop="siteName">
              <el-input
                v-model="form.companyInfo"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态：" prop="siteName">
              <el-input
                v-model="form.status"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="创建时间：" prop="siteName">
              <el-input
                v-model="form.createDt"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="提交用户名称：" prop="siteName">
              <el-input
                v-model="form.memberName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="审核" :visible.sync="dialogVisible" width="30%">
      <div class="flex">
        <div style="width:80px">审核意见</div>
        <el-select v-model="reviewStatus" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex mp10" v-if="reviewStatus == 2">
        <div style="width:100px">拒绝原因</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容"
          v-model="rejectReason"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-row type="flex" justify="center">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="reviweSave">确 定</el-button>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      dialogVisible: false,
      options: [
        {
          value: "0",
          label: "审核中",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核拒绝",
        },
      ],
      reviewStatus: "",
      id: "",
      rejectReason:""
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/company/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      that.$http
        .post("/company/save", that.form)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {});
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/company/delete", { id: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },
    //审核
    review(row) {
      console.log(row, 419);
      this.id = row.id;
      row.status ? (this.reviewStatus = row.status) : (this.reviewStatus = 0);
      this.dialogVisible = true;
    },
    async reviweSave() {
      const res = await this.$http.post("company/checkCompany", {
        id: this.id,
        status: this.reviewStatus,
        rejectReason:this.rejectReason
      });
      if (res.data.code === 200) {
        this.dialogVisible = false;
        this.getList();
        this.rejectReason = "";
        this.reviewStatus = ""
      } else {
        this.$message.error(res.data.message);
      }
    },
    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
  },
};
</script>

<style scoped>
.input-with-select {
  width: 350px !important;
}
.edit {
  color: brown;
  margin-left: 20px;
  cursor: pointer;
}

.review {
  color: orange;
  margin-left: 15px;
  cursor: pointer;
}

.mp10 {
  margin-top: 10px;
}
</style>
